import { ALL_ROLES, ROLES } from 'constants/roles'

export enum ProfileSteps {
  generalTab = 1,
  reviewTab = 2,
  benefitsTab = 3,
  hardwareTab = 4,
  educationTab = 5,
  jobTab = 6,
  documentsTab = 7,
  accessTab = 8,
  integrationTab = 9,
  socialNetwork = 10,
}

type UserRolesData = Record<ProfileSteps, ROLES[]>

const excludeRoles = (...roles: ROLES[]): ROLES[] =>
  ALL_ROLES.filter((role) => !roles.includes(role))

export const CurrentUserProfileStepsByRoles: UserRolesData = {
  [ProfileSteps.generalTab]: ALL_ROLES,
  [ProfileSteps.reviewTab]: ALL_ROLES,
  [ProfileSteps.benefitsTab]: ALL_ROLES,
  [ProfileSteps.hardwareTab]: ALL_ROLES,
  [ProfileSteps.educationTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.admin,
  ],
  [ProfileSteps.jobTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.admin,
  ],
  [ProfileSteps.documentsTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.admin,
  ],
  [ProfileSteps.accessTab]: ALL_ROLES,
  [ProfileSteps.integrationTab]: [ROLES.recruiter, ROLES.admin],
  [ProfileSteps.socialNetwork]: ALL_ROLES,
}

export const OtherUserProfileStepsByRoles: UserRolesData = {
  [ProfileSteps.generalTab]: ALL_ROLES,
  [ProfileSteps.reviewTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.admin,
  ],
  [ProfileSteps.benefitsTab]: [ROLES.hr, ROLES.admin, ROLES.sysadmin],
  [ProfileSteps.hardwareTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.sysadmin,
    ROLES.admin,
  ],
  [ProfileSteps.educationTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.admin,
  ],
  [ProfileSteps.jobTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.admin,
    ROLES.owner,
  ],
  [ProfileSteps.documentsTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.admin,
  ],
  [ProfileSteps.accessTab]: excludeRoles(ROLES.user, ROLES.owner),
  [ProfileSteps.integrationTab]: [ROLES.admin],
  [ProfileSteps.socialNetwork]: [ROLES.admin, ROLES.hr, ROLES.recruiter],
}

export const DismissedUserProfileStepsByRoles: Record<
  ProfileSteps.generalTab | ProfileSteps.accessTab,
  ROLES[]
> = {
  [ProfileSteps.generalTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.sysadmin,
    ROLES.admin,
    ROLES.owner,
  ],
  [ProfileSteps.accessTab]: [
    ROLES.hr,
    ROLES.recruiter,
    ROLES.customer,
    ROLES.sysadmin,
    ROLES.admin,
    ROLES.owner,
  ],
}
