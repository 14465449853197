import { baseApi } from 'store/api/baseApi'
import { Notification, PaginationParams } from 'types/model/notification'
import { ExtendedResponseType } from 'types/utils'

export const notificationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Получить список уведомлений
    getNotifications: builder.query<
      ExtendedResponseType<Notification>,
      { userId: number; pagination?: PaginationParams }
    >({
      query: ({ userId, pagination }) => {
        const params = new URLSearchParams()
        if (pagination) {
          Object.entries(pagination).forEach(([key, value]) => {
            value && params.append(key, String(value))
          })
        }
        return `/notification/${userId}?${params}`
      },
    }),
  }),
})

export const { useGetNotificationsQuery, useLazyGetNotificationsQuery } =
  notificationApi
