import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { Link } from 'react-router-dom'
import { useSetupInterceptors } from 'hooks'
import { NotificationTypes } from 'constants/notificationTypes'
import { useAuth } from 'hooks/useAuth'
import { ExtendedResponseType } from 'types/utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'components/ui/Loader'
import EmptyResult from 'components/views/feedback/EmptyResult'
import { removeNotification } from 'api/userPage'
import { Notification } from 'types/model/notification'

interface Props {
  isLoadingNotification?: boolean
  show: boolean
  onClose: (isShow: boolean) => void
  next: () => void
  hasMore: boolean
  notifications: ExtendedResponseType<Notification> | null
  removeNotificationById?: (id: number) => void
}

const scrollableId = 'notificationsContainer'

const Notifications: FC<Props> = ({
  isLoadingNotification,
  show,
  onClose,
  notifications,
  next,
  hasMore,
  removeNotificationById,
}) => {
  const { token } = useAuth()

  useSetupInterceptors(token)

  const handleClose = (): void => {
    onClose(!show)
  }

  return (
    <>
      <ST.MenuOverlay onClick={handleClose} isActive={show} />
      <ST.MenuContainer
        onClick={(e) => e.stopPropagation()}
        isActive={show}
        id={scrollableId}
      >
        <ST.MenuHeader>
          <ST.MenuTitle>Задачи</ST.MenuTitle>
          <ST.CloseButton onClick={handleClose}>
            <Close />
          </ST.CloseButton>
        </ST.MenuHeader>

        {isLoadingNotification ? (
          <Loader marginTop={-50} />
        ) : notifications?.data.length ? (
          <InfiniteScroll
            next={next}
            hasMore={hasMore}
            loader={<Loader marginTop={40} />}
            dataLength={notifications.data.length}
            scrollableTarget={scrollableId}
          >
            <ST.ListItems>
              {notifications.data
                .filter((n) => n.status === NotificationTypes.SENT)
                .map((notification) => {
                  const linkData = notification.link?.split('?') ?? []

                  return (
                    <ST.Item key={notification.id}>
                      {/* <ST.Close
                        onClick={() => {
                          removeNotificationById?.(notification.id)
                        }}
                      >
                        <Close />
                      </ST.Close> Добавить логику удаления уведомлений по необходимости */}
                      <ST.NotificationMessage maxWidth={300}>
                        {notification.message}
                      </ST.NotificationMessage>
                      {notification.external ? (
                        <ST.Link href={notification.link} target={'_blank'}>
                          <ST.Button>{notification.linkText}</ST.Button>
                        </ST.Link>
                      ) : (
                        <Link
                          to={{ pathname: linkData[0], search: linkData[1] }}
                          onClick={handleClose}
                        >
                          <ST.Button>{notification.linkText}</ST.Button>
                        </Link>
                      )}
                    </ST.Item>
                  )
                })}
            </ST.ListItems>
          </InfiniteScroll>
        ) : (
          <EmptyResult
            orientation="vertical"
            title="Нет уведомлений"
            style={{ marginTop: '50%' }}
          />
        )}
      </ST.MenuContainer>
    </>
  )
}

export default Notifications
