import { UserEducationResponse } from 'types/userProfileTypes'
import { IFormDocumentsTab } from 'types/employment/documents'
import { TDeleteFileId, TEducation } from 'types/employmentType'
import { handlerError } from 'utils/handlerError'
import { SocialNetworks } from 'constants/socialNetworks'
import {
  apiInstance,
  apiInstanceForFile,
  apiInstanceForFileJSON,
  apiInstanceWithToken,
} from 'api'
import { apiRequest } from 'api/request'
import { DocumentTypes } from 'constants/documentTypes'
import { UserTypes } from 'types/model/user'
import { ExtendedResponseType } from 'types/utils'
import { BenefitApplicationStatuses } from 'constants/benefitApplicationStatuses'
import { AxiosResponse } from 'axios'
import { Notification } from 'types/model/notification'

interface IDeleteWorkplaceResponse {
  status: number
  message: string
  detail: string
  validationError: {
    query: { name: string; message: string }[]
    body: { name: string; message: string }[]
  }
}

type PaginationParams = {
  pageNumber?: number
  pageSize?: number
}

export const getUserPage = async (id: number): Promise<UserTypes.UserInfo> => {
  return apiInstance
    .get<UserTypes.UserInfo>(`/api/user/info?user=${id}`)
    .then((resp) => resp.data)
}

export const getUserFIO = async (id: number): Promise<UserTypes.FullName> => {
  return apiInstance
    .get<UserTypes.FullName>(`/api/user/${id}`)
    .then((resp) => resp.data)
}

export const getUserGeneral = async (
  id: number,
  token?: string
): Promise<UserTypes.GeneralTabData> => {
  return apiInstanceWithToken(token)
    .get<UserTypes.GeneralTabData>(`/api/user/card/general?user=${id}`)
    .then((resp) => resp.data)
}

export const getUserEducation = async (
  id: number,
  token?: string
): Promise<UserEducationResponse> => {
  return apiInstanceWithToken(token)
    .get<UserEducationResponse>(`/api/user/card/education?user=${id}`)
    .then((resp) => resp.data)
}

export const deleteUserEducation = async (
  id: number | undefined | null
): Promise<UserTypes.Education> => {
  return apiInstance.delete(`/api/institute/${id}`).then((resp) => resp.data)
}

export const getUserEducationLevels = async (token?: string) => {
  return apiInstanceWithToken(token)
    .get<UserTypes.EducationLevelDto>(`/api/institute/education/levels`)
    .then((resp) => resp.data)
}

export const getUserDocs = async (
  id: number,
  token?: string
): Promise<UserTypes.DocumentsTabData> => {
  return apiInstanceWithToken(token)
    .get<UserTypes.DocumentsTabData>(`/api/user/card/documents?user=${id}`)
    .then((resp) => resp.data)
}

export const getUserWorkPlaces = async (
  id: number
): Promise<UserTypes.WorkplacesTabData> => {
  return apiInstance
    .get<UserTypes.WorkplacesTabData>(`/api/user/card/workplaces?user=${id}`)
    .then((resp) => resp.data)
}

export const updateUserWorkPlaces = async (
  workPlaceId: number,
  data: Omit<UserTypes.Workplace, 'id'>
): Promise<UserTypes.Workplace> => {
  return apiInstance
    .patch<UserTypes.Workplace>(`/api/workplace/${workPlaceId}`, data)
    .then((resp) => resp.data)
}

export const addUserWorkplace = async (
  userId: number,
  data: Omit<UserTypes.Workplace, 'id'>
): Promise<{ id: number }> => {
  return apiInstance
    .post(`/api/workplace?user=${userId}`, data)
    .then((resp) => resp.data)
}

export const deleteUserWorkplace = async (
  workplaceId: number
): Promise<IDeleteWorkplaceResponse> => {
  return apiRequest()
    .delete<IDeleteWorkplaceResponse>(`/api/workplace/${workplaceId}`)
    .then((resp) => resp.data)
}

export const getUserInfo = async (
  userId?: number,
  token?: string
): Promise<UserTypes.UserInfo> => {
  const query = new URLSearchParams()
  userId && query.append('user', String(userId))

  return apiInstanceWithToken(token)
    .get(`/api/user/info?${query}`)
    .then((resp) => resp.data)
}

export interface IDismissUserApi {
  dismissDate: string
  dismissReason: string
  dismissStatement: number | string
}

export const dismissUser = async (
  userId: number,
  data: IDismissUserApi
): Promise<number> => {
  return apiInstance
    .patch(`/api/user/dismiss/${userId}`, data)
    .then((resp) => resp.data)
}

export const getUserScans = async (
  userId: number
): Promise<UserTypes.Scans[]> => {
  return apiInstance
    .get(`/api/document/user?userId=${userId}`)
    .then((resp) => resp.data)
}

export const downLoadFile = async (docUrl: string) => {
  return apiInstanceForFile.get(docUrl).then((resp) => resp.data)
}

export const getUserDocuments = async (
  userId?: number,
  token?: string
): Promise<UserTypes.DocumentsTabData> => {
  const query = new URLSearchParams()

  userId && query.append('user', String(userId))

  return apiInstanceWithToken(token)
    .get(`/api/user/documents?${query}`)
    .then((resp) => resp.data)
}

export const updateUserDocs = async (
  userId: number,
  data: IFormDocumentsTab
): Promise<number> => {
  return apiInstance
    .patch(`/api/user/documents?user=${userId}`, data)
    .then((resp) => resp.data)
}

export const uploadDocuments = async (
  userId: number,
  data: {
    types: DocumentTypes[]
    files?: File[]
    customNames?: string[]
    customFiles?: File[]
  }
): Promise<UserTypes.DocumentsTabData> => {
  const params = new URLSearchParams()
  const formData = new FormData()
  if (data.types.length) {
    let types: string
    let dataTypes = data.types.filter((e) => !!e)
    if (dataTypes.length === 1) {
      // @ts-ignore
      types = dataTypes[0]
    } else {
      types = dataTypes.join(',')
    }
    formData.append('types', types)
  }

  if (data.files?.length) {
    data.files.forEach((element: File | string) => {
      typeof element !== 'string' && formData.append('files[]', element)
    })
  }

  if (data.customNames?.length) {
    formData.append('customNames', data.customNames.join(','))
  }

  if (data.customFiles?.length) {
    data.customFiles.forEach((element: File | string) => {
      typeof element !== 'string' && formData.append('customFiles[]', element)
    })
  }

  params.append('userId', String(userId))

  return apiInstanceForFileJSON
    .post(`/api/document/upload-user-files?${params}`, formData)
    .then((resp) => resp.data)
}

export const deleteScan = async (
  documentId?: number
): Promise<TDeleteFileId> => {
  try {
    const resp = await apiInstance.delete(`/api/document/${documentId}`)
    return {
      isSuccess: true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: false, error: err }
  }
}

export const updateEducationLevel = async (
  userId: number,
  levelId: number
): Promise<number> => {
  return apiInstance
    .patch(`/api/institute?userId=${userId}`, { educationLevel: levelId })
    .then((resp) => resp.data)
}

interface IEducationInstitute {
  id?: number
  name: string
  yearEntered: number | null
  yearEnded?: number | null
  faculty: string
  speciality: string
  directionTraining: string
  qualification: string
  diploma?: number
}

export const updateEducation = async (
  instituteId: number,
  data: IEducationInstitute
): Promise<number> => {
  return apiInstance
    .patch(`/api/institute/${instituteId}`, data)
    .then((resp) => resp.data)
}

export const postUserEducationInEditing = async (
  userId: number,
  data: IEducationInstitute
): Promise<TEducation> => {
  return apiInstance
    .post(`/api/institute?user=${userId}`, data)
    .then((resp) => resp.data)
}

export const changeUserInfo = async (
  userId: number,
  data: Object
): Promise<number> => {
  return apiInstance
    .patch(`/api/user/card/general?user=${userId}`, data)
    .then((resp) => resp.data)
}

export const addSocialNetwork = async (
  userId: number,
  name: SocialNetworks,
  link: string
): Promise<number> => {
  return apiInstance
    .post(`/api/social/network/?user=${userId}`, { name: name, link: link })
    .then((resp) => resp.data)
}

export const deleteUserSocialNetwork = async (id: number): Promise<number> => {
  return apiInstance
    .delete(`/api/social/network/${id}`)
    .then((resp) => resp.data)
}

export const updateUserSocialNetwork = async (
  id: number,
  name: SocialNetworks,
  link: string
): Promise<number> => {
  return apiInstance
    .patch(`/api/social/network/${id}`, { name: name, link: link })
    .then((resp) => resp.data)
}

export const confirmData = (id: number): Promise<void> => {
  return apiInstance.get(`/api/user/status/${id}`).then((resp) => resp.data)
}

export const postUserPersonalEditing = async (
  userId: number,
  body: UserTypes.Personal
): Promise<void> => {
  return apiInstance
    .post(`/api/user/personal?user=${userId}`, body)
    .then((resp) => resp.data)
}

export const getNotifications = async (
  userId: number,
  pagination?: PaginationParams
): Promise<ExtendedResponseType<Notification>> => {
  const params = new URLSearchParams()

  if (pagination) {
    Object.entries(pagination).forEach(([key, value]) => {
      value && params.append(key, String(value))
    })
  }

  return apiInstance
    .get(`/api/notification/${userId}?${params}`)
    .then((resp) => resp.data)
}

export const removeNotification = async (
  userId: number,
  notificationId: number
): Promise<AxiosResponse<any>> => {
  return apiInstance
    .delete(`/api/notification/${userId}/remove/${notificationId}`)
    .then((resp) => resp.data)
}

export const notifyAdminUserAccess = async (userId: number): Promise<void> => {
  return apiInstance
    .get(`/api/notification/withdrawal-access-notify/${userId}`)
    .then((resp) => resp.data)
}

export const uploadProfileAvatar = async (
  userId: number,
  file: File
): Promise<void> => {
  const formData = new FormData()
  formData.append('file', file)

  return apiInstance
    .post(`/api/user/avatar/${userId}`, formData)
    .then((resp) => resp.data)
}

export const getDocsByArchive = async (
  userId: number,
  types: DocumentTypes[],
  withStatements: boolean
): Promise<string> => {
  const params = new URLSearchParams()
  params.append('userId', userId.toString())
  if (!!types.length) {
    types.map((type) => params.append('types', type.toString()))
  }
  return apiInstance
    .post(`/api/document/user/archive?userId=${userId}`, {
      types: types,
      withStatements: withStatements,
    })
    .then((resp) => resp.data)
}

export const getUserCardBenefitApplications = async (
  userId?: number,
  args?: {
    pageNumber?: number
    pageSize?: number
    status?: BenefitApplicationStatuses
  }
): Promise<ExtendedResponseType<UserTypes.BenefitApplication>> => {
  const params = new URLSearchParams()

  userId && params.append('userId', userId.toString())
  args &&
    Object.entries(args).forEach(([key, value]) => {
      value !== undefined && params.append(key, String(value))
    })

  return apiInstance
    .get<ExtendedResponseType<UserTypes.BenefitApplication>>(
      `/api/user/card/benefitApplications?${params}`
    )
    .then((resp) => resp.data)
}

export const getUserCardBenefitWallet = async (
  userId?: number
): Promise<UserTypes.BenefitWallet> => {
  const params = new URLSearchParams()

  userId && params.append('userId', userId.toString())

  return apiInstance
    .get<{ benefitWallet: UserTypes.BenefitWallet }>(
      `/api/user/card/benefitWallet?${params}`
    )
    .then((resp) => resp.data.benefitWallet)
}

export const getUserWordFile = async (
  userId: number
): Promise<UserTypes.UserWordFile> =>
  apiInstance.get(`/api/user/docx/${userId}`).then((resp) => resp.data)

export const getUserCardIntegrations = async (
  userId?: number
): Promise<UserTypes.UserIntegration[]> => {
  const params = new URLSearchParams()

  userId && params.append('userId', userId.toString())

  return apiInstance
    .post<UserTypes.UserIntegration[]>(`/api/user/card/integrations?${params}`)
    .then((resp) => resp.data)
}

export const removeIntegration = async (
  service: number,
  userId?: number
): Promise<AxiosResponse> => {
  const params = new URLSearchParams()

  params.append('service', service.toString())

  userId && params.append('userId', userId.toString())

  return apiInstance.post<UserTypes.UserIntegration[]>(
    `/api/user/card/integration/logout?${params}`
  )
}
